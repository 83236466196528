import React from "react";
import styled from "styled-components";
import { Link, graphql } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Nav from "../components/Nav";
import Section from "../components/Section";
import SectionSplit from "../components/SectionSplit";
import Article from "../components/Article";
import H1 from "../components/H1";
import H2 from "../components/H2";
import Paragraph from "../components/Paragraph";

const getSeasonPhoto = data => {
  const photoDroneSummer = data.droneSummer.childImageSharp.fluid.src;
  const photoDroneWinter = data.droneWinter.childImageSharp.fluid.src;

  const thisMonth = new Date().getMonth() + 1;
  switch (thisMonth) {
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
      return photoDroneSummer;
      break;
    case 9:
    case 10:
    case 11:
    case 12:
    case 1:
    case 2:
      return photoDroneWinter;
      break;
    default:
      return photoDroneSummer;
  }
};

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Welcome" />
      <Cover background={data.backgroundCover.childImageSharp.fluid.src}>
        <Nav isCover={true} />
      </Cover>
      <SectionSplit
        photo={getSeasonPhoto(data)}
        footnote="The Oak Hall estate sits within a vast Acadian forest on land that was once part of  the Waldo Patent — granted under authority of  King Charles II in 1630."
      >
        <Article>
          <H1>Built in the Twilight of the Gilded Age</H1>
          <Paragraph isLarge>
            Tucked high on a hill above Penobscot Bay, Oak Hall sits on a 52 acre estate designed by
            Warren H. Manning. Built in 1913 for Chicago financier Ira M. Cobe, it is the largest
            Colonial Revival mansion in Maine. Built to last for generations, the house has been
            called home by five families. It was listed on the National Register of Historic Places
            in 1983.
          </Paragraph>
          <Paragraph>
            Oak Hall is located in Bayside, a picturesque Victorian village nestled on the coast
            between Camden to the south and Belfast to the north. The house is a large three story
            structure, with collonaded porticos on each face, exquisite carved woodwork and plaster
            throughout, and large, light filled rooms. The house is a private residence but is open
            on select days for tours throughout the year.
          </Paragraph>
        </Article>
      </SectionSplit>
      <Section>
        <Map to="/northport" background={data.maineMap.childImageSharp.fluid.src}>
          <MapLocation />
          <MapDescription>
            <H2>Northport, Maine</H2>
            <Paragraph>
              Oak Hall is located in northern Northport, in the village of Bayside, nestled between
              Camden to the south and Belfast to the north.
            </Paragraph>
          </MapDescription>
        </Map>
      </Section>
    </Layout>
  );
};

const Cover = styled.section<{ background: string }>`
  margin: var(--section-spacing) 0;
  background-image: url(${props => props.background});
  background-color: #96b4b8;
  background-size: cover;
  background-position: center;
  position: relative;
  width: 100%;
  height: 100vh;
  max-width: 1920px;
  max-height: 1200px;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;

  @media (max-height: 848px) {
    height: 100vh;
  }

  &:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 40%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
  }
`;

const Map = styled(Link)<{ background: string }>`
  display: block;
  background-image: url(${props => props.background});
  background-size: cover;
  background-position: center;
  background-color: #222;
  position: relative;
  margin-bottom: var(--section-spacing);
  transition: transform 250ms;

  &:hover {
    transform: scale(1.025);

    &:after,
    > h4 {
      opacity: 0;
    }
  }

  &:before {
    position: absolute;
    content: "";
    width: 75%;
    height: 100%;
    right: 0;
    top: 0;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 32px;
    border: 1px solid rgba(255, 255, 255, 0.25);
    transition: all 0.2s;
  }
`;

const MapDescription = styled.div`
  position: relative;
  z-index: 1;
  display: grid;
  grid-gap: 16px;
  padding: 256px 64px 64px 64px;
  color: #fff;
  width: 33%;
  margin-left: auto;
  box-sizing: border-box;

  @media (max-width: 960px) {
    width: 50%;
  }

  @media (max-width: 512px) {
    width: 100%;
  }
`;

const MapLocation = styled.span`
  position: absolute;
  top: 120px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #e8d9a4;

  &:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    border: 1px solid #e8d9a4;
    border-radius: 50%;
    opacity: 0.5;
    animation-name: beacon;
    animation-duration: 4s;
    animation-iteration-count: infinite;
  }

  @keyframes beacon {
    0% {
      width: 10px;
      height: 10px;
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      width: 125px;
      height: 125px;
      opacity: 0;
    }
  }
`;

export const query = graphql`
  query{
    backgroundCover: file(absolutePath: {regex: "/\\/index\\/background-cover\\.jpg/"}) {
      childImageSharp {
        fluid(maxWidth: 2880, jpegQuality: 100) {
          originalName
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    droneSummer: file(absolutePath: {regex: "/\\/index\\/drone-summer\\.jpg/"}) {
      childImageSharp {
        fluid(maxWidth: 840) {
          originalName
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    droneWinter: file(absolutePath: {regex: "/\\/index\\/drone-winter\\.jpg/"}) {
      childImageSharp {
        fluid(maxWidth: 840) {
          originalName
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    maineMap: file(absolutePath: {regex: "/\\/index\\/maine-map\\.jpg/"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          originalName
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default IndexPage;
