import React from "react";
import styled, { ThemeProvider, css } from "styled-components";

import Container from "../components/Container";
import Footnote from "../components/Footnote";

const SectionSplit = ({ orientation = "left", photo, footnote, children }) => {
  return (
    <ThemeProvider theme={{ orientation }}>
      <Frame>
        <SplitSectionContainer>
          {children}
          <Figure>
            <Photo src={photo} />
            <Footnote>{footnote}</Footnote>
          </Figure>
        </SplitSectionContainer>
      </Frame>
    </ThemeProvider>
  );
};

const Frame = styled.section`
  --inner-spacing: 96px;
  position: relative;
  margin: var(--section-spacing) 0;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    right: 0;
    bottom: 0;
    background-color: #fff;
    box-shadow: 0px 64px 128px rgba(0, 0, 0, 0.05);

    ${props =>
      props.theme.orientation === "REVERSE" &&
      `
        right: 50%;
        left: 0;
      `}

    @media (max-width: 960px) {
      display: none;
    }
  }

  @media (max-width: 960px) {
    --inner-spacing: 64px;
  }

  @media (max-width: 512px) {
    --inner-spacing: 32px;
  }
`;

const SplitSectionContainer = styled(Container)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  position: relative;
  z-index: 1;

  ${props => {
    if (props.theme.orientation === "REVERSE") {
      return css`
        > *:first-child {
          grid-column-start: 2;
          grid-row-start: 1;
          margin: var(--inner-spacing) 0 var(--inner-spacing) var(--inner-spacing);
        }

        > *:last-child {
          grid-column-start: 1;
          grid-row-start: 1;
          padding-right: var(--inner-spacing);
          padding-left: 0;
        }
      `;
    } else {
      return css`
        > *:first-child {
          margin: var(--inner-spacing) var(--inner-spacing) var(--inner-spacing) 0;
        }
      `;
    }
  }}

  @media (max-width: 960px) {
    grid-template-columns: 1fr;

    /* {children} (i.e <Article>) */
    > *:first-child {
      margin-left: calc(var(--inner-spacing) / 2);
      margin-right: calc(var(--inner-spacing) / 2);
      margin-top: 0;
    }

    // <Footnote>
    > *:last-child {
      background-color: #fff;
    }

    ${props => {
      if (props.theme.orientation === "REVERSE") {
        return css`
          // {children} (i.e <Article>)
          > *:first-child {
            grid-column-start: 1;
            grid-row-start: 1;
          }

          // <Figure>
          > *:last-child {
            grid-column-start: 1;
            grid-row-start: 2;
            margin-left: calc(var(--viewport-spacing) * -1);
            box-shadow: calc(var(--inner-spacing) * -1) 0 #fff;

            // <Footnote>
            > *:last-child {
              text-align: right;
              margin-left: auto;
            }
          }
        `;
      } else {
        return css`
          // <Figure>
          > *:last-child {
            margin-right: calc(var(--viewport-spacing) * -1);
            box-shadow: var(--inner-spacing) 0 #fff;
          }
        `;
      }
    }}
  }
`;

const Figure = styled.figure`
  display: grid;
  grid-gap: 16px;
  box-sizing: border-box;

  padding: ${props => {
    if (props.theme.orientation === "right") {
      return `
        var(--inner-spacing) var(--inner-spacing) var(--inner-spacing) 0;
      `;
    } else {
      return `
        var(--inner-spacing) 0 var(--inner-spacing) var(--inner-spacing);
      `;
    }
  }};
`;

const Photo = styled.img`
  max-width: 100%;
`;

export default SectionSplit;
