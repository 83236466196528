import React from "react";
import styled from "styled-components";

const Footnote = ({ className = "", children }) => {
  return <Frame className={className}>{children}</Frame>;
};

const Frame = styled.p`
  display: block;
  font-style: italic;
  color: #999;
  font-size: 0.8rem;
  line-height: 1.25em;
  max-width: 75%;
  align-self: end;
`;

export default Footnote;
